import { Box, Typography } from "@mui/material";
import { useTheme, SxProps } from "@mui/material/styles";
import { useState } from "react";
import Carousel from "src/components/Common/Carousel";

import { Player } from "src/components/Common/Player";
import { STATIC_CONTENTS_BASE_URL } from "src/constant";

type TestimonialVideosProps = {
  title: string;
  background?: string;
  themeType?: "dark" | "light";
};

export const TestimonialVideos = ({
  title,
  background,
  themeType,
}: TestimonialVideosProps) => {
  const theme = useTheme();

  const wrapperStyle: SxProps = () => ({
    py: 12,
    width: "100%",
    backgroundColor: background || theme.palette.background.paper,
    textAlign: "center",
    color:
      themeType === "dark"
        ? theme.palette.primary.contrastText
        : theme.palette.text.primary,
  });

  const slideItemStyle: SxProps = () => ({
    overflow: "hidden",
    mx: 2,
    "& .testimonial-video-wrapper": {
      width: "100%",
      height: "100%",
      margin: "auto",
      "& video": {
        maxHeight: "70vh",
        objectFit: "cover",
      },
    },

    [theme.breakpoints.down("md")]: {
      mx: 1,
    },
  });

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const pauseVideoOnSlideChange = () => {
    setIsVideoPlaying(true);
    setTimeout(() => {
      setIsVideoPlaying(false);
    }, 0);
  };

  const videos = [
    {
      videoSrc: `${STATIC_CONTENTS_BASE_URL}/front/videos/testimonial-1.mp4`,
      posterSrc: `${STATIC_CONTENTS_BASE_URL}/front/videos/testimonial-1.jpg`,
    },
    {
      videoSrc: `${STATIC_CONTENTS_BASE_URL}/front/videos/testimonial-2.mp4`,
      posterSrc: `${STATIC_CONTENTS_BASE_URL}/front/videos/testimonial-2.jpg`,
    },
    {
      videoSrc: `${STATIC_CONTENTS_BASE_URL}/front/videos/testimonial-3.mp4`,
      posterSrc: `${STATIC_CONTENTS_BASE_URL}/front/videos/testimonial-3.jpg`,
    },
  ];

  return (
    <Box sx={wrapperStyle}>
      <Typography component="h3" variant="desktopH1" mb={6}>
        {title}
      </Typography>
      <Carousel
        options={{ loop: true }}
        onChangeSlide={pauseVideoOnSlideChange}
      >
        {videos.map((item, index) => (
          <div key={index} style={{ flex: "0 0 85%" }}>
            <Box sx={slideItemStyle}>
              <div className="testimonial-video-wrapper">
                <Player {...item} isVideoPlaying={isVideoPlaying} />
              </div>
            </Box>
          </div>
        ))}
      </Carousel>
    </Box>
  );
};
