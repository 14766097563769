import { SVGProps, memo } from "react";

const SvgBeijeDot12 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="67"
    height="66"
    viewBox="0 0 67 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.414 4.47627C27.0224 -0.994379 35.9968 1.00783 44.1137 3.047C51.2075 4.83359 57.0577 10.3905 61.646 17.6724C66.2344 24.9542 67.5698 31.5769 66.7918 39.8137C65.9036 49.2395 59.4469 54.5377 52.1999 60.4827C44.9529 66.4277 41.8593 66.8836 33.7486 64.8444C26.6548 63.0578 22.4402 65.1771 15.279 60.4642C6.70883 54.8211 -0.721914 42.3642 0.0560775 34.1213C0.944336 24.6955 8.46697 12.3496 19.414 4.47627Z"
      fill={props.fill || "#F38054"}
    />
  </svg>
);

const Memo = memo(SvgBeijeDot12);
export default Memo;
