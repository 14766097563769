import { PlayCircle, PauseCircle } from "@beije/react-icons";
import { Box } from "@mui/material";
import { SxProps } from "@mui/material/styles";
import { useState, useRef, useEffect } from "react";
import { useIsInViewport } from "src/utils/hooks/useIsInViewport";

type PlayerProps = {
  posterSrc: string;
  videoSrc: string;
  isVideoPlaying: boolean;
};

const Player = ({ posterSrc, videoSrc, isVideoPlaying }: PlayerProps) => {
  const playerRef = useRef<HTMLVideoElement>(null);
  const wrapperRef = useRef<HTMLElement>(null);
  const isPlayerInViewport = useIsInViewport(wrapperRef);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const playVideo = () => {
    playerRef.current?.play();
    setIsPlaying(true);
  };

  const pauseVideo = () => {
    playerRef.current?.pause();
    setIsPlaying(false);
  };

  const toggle = () => {
    if (playerRef.current?.paused) {
      playVideo();
    } else {
      pauseVideo();
    }
  };

  // pause video when outside veiwport
  useEffect(() => {
    if (!isPlayerInViewport) pauseVideo();
  }, [isPlayerInViewport]);

  // change video play status from parent component
  useEffect(() => {
    if (isVideoPlaying) return;
    pauseVideo();
  }, [isVideoPlaying]);

  const wrapperStyle: SxProps = () => ({
    position: "relative",

    "&:hover": {
      "& > button": {
        opacity: 1,
      },
    },

    "& > button": {
      transition: "all ease-in 400ms",
      opacity: 0,
      width: "64px",
      height: "64px",
      position: "absolute",
      left: 0,
      right: 0,
      margin: "auto",
      top: "45%",
      zIndex: 1,
      borderRadius: "50%",
      background: "rgba(0, 0, 0, 0.3)",
    },
  });

  return (
    <Box sx={wrapperStyle} ref={wrapperRef}>
      <button type="button" onClick={toggle}>
        {isPlaying ? (
          <PauseCircle width={42} height={42} stroke="#ffffff" />
        ) : (
          <PlayCircle width={42} height={42} stroke="#ffffff" />
        )}
      </button>
      <video
        height="100%"
        width="100%"
        ref={playerRef}
        preload="none"
        poster={posterSrc}
      >
        <source src={`${videoSrc}#t=0.001`} type="video/mp4" />
        Video Cannot Be Played
      </video>
    </Box>
  );
};

export default Player;
