import { SVGProps, memo } from "react";

const SvgDot14 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="69"
    height="111"
    viewBox="0 0 69 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.32654 62.9543C-2.04249 52.55 1.28052 39.9056 4.02917 28.9105C6.42501 19.3021 14.0884 11.6876 24.1805 5.93573C34.2725 0.183822 40.5493 0.249469 52.0362 1.93976C65.1805 3.87621 71.2439 12.9102 79.6129 23.3145C87.9819 33.7188 92.987 36.8533 90.2465 47.8484C87.8507 57.4567 87.7276 66.6385 77.6355 72.3904C67.5435 78.1423 59.3632 93.5026 47.8763 91.8123C34.732 89.8758 14.6956 73.3668 6.32654 62.9625V62.9543Z"
      fill="#FFC906"
    />
    <path
      d="M37.4513 102.85C35.3912 105.942 32.902 107.917 29.7576 109.393C26.5559 110.899 23.4429 111.131 19.8808 110.823C16.4531 110.528 12.3501 111.447 9.29151 109.353C6.27296 107.285 5.45467 103.207 3.96971 100.097C2.43898 96.8949 -0.227647 93.684 0.0155538 90.1338C0.250171 86.6879 1.26876 82.5227 3.30878 79.4627C5.3488 76.4026 9.03114 74.3693 12.1155 72.832C15.2857 71.2512 19.1397 69.7545 22.6504 69.9605C26.0809 70.1606 30.1238 71.4687 33.1538 73.5455C36.2238 75.6484 35.3283 79.0247 36.8562 82.1805C38.4355 85.4436 40.1894 89.4811 39.9834 93.0778C39.7832 96.6165 39.5142 99.7578 37.4541 102.847L37.4513 102.85Z"
      fill="#005743"
    />
  </svg>
);

const Memo = memo(SvgDot14);
export default Memo;
