import { Container, Box as MuiBox, useMediaQuery } from "@mui/material";
import { useTheme, Theme } from "@mui/material/styles";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import placeholderImage from "public/images/placeholder.png";
import { FC } from "react";

import { Banner, CallToAction } from "src/components/Common";

const OneOfUs: FC = () => {
  const theme: Theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up("md"));

  const { t } = useTranslation();

  const router = useRouter();

  const handleNavigate = (path: string): void => {
    router.push(path);
  };

  return (
    <Container
      component="section"
      maxWidth="lg"
      sx={{
        paddingY: 4,
      }}
    >
      <Banner
        title={t("home:one-of-us.title")}
        desc={t("home:one-of-us.desc")}
        image={placeholderImage.src}
        imageWidth={552}
        imageHeight={400}
        buttonText={t("home:one-of-us.button")}
        buttonType="outline"
        onButtonClick={() => handleNavigate("/")}
      />

      <MuiBox
        component="section"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={breakpoint ? "row" : "column"}
        gap={6}
        margin="5rem auto"
      >
        <CallToAction
          color={theme.palette.grey[500]}
          title={t("home:why.boxs.product.title")}
          desc={t("home:why.boxs.product.desc")}
          btn={{
            text: t("home:why.boxs.product.button-title"),
            onClick: () => {
              handleNavigate("/packets");
            },
          }}
        />

        <CallToAction
          color={theme.palette.secondary.light}
          title={t("home:why.boxs.quiz.title")}
          desc={t("home:why.boxs.quiz.desc")}
          btn={{
            text: t("home:why.boxs.quiz.button-title"),
            onClick: () => {
              handleNavigate("/quiz");
            },
          }}
        />
      </MuiBox>
    </Container>
  );
};

export default OneOfUs;
