import { Box } from "@mui/material";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import placeholderImage from "public/images/placeholder.png";
import type { FC } from "react";

import { Banner } from "src/components/Common";

const Quiz: FC = () => {
  const { t } = useTranslation();

  const router = useRouter();

  const handleQuizBannerClick = () => {
    router.push("/quiz");
  };

  return (
    <Box
      component="section"
      sx={{
        backgroundColor: "#999999",
      }}
      paddingY={4}
    >
      <Banner
        title=""
        desc={t("product:quiz-banner.desc")}
        image={placeholderImage.src}
        imageWidth={360}
        imageHeight={220}
        buttonText={t("product:quiz-banner.button")}
        buttonType="primary"
        onButtonClick={handleQuizBannerClick}
      />
    </Box>
  );
};

export default Quiz;
