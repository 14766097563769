import { useMediaQuery, Container, Box, Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Image from "next/image";
import { useTranslation } from "next-i18next";

import ToTheRoadImage from "public/images/to-the-road.png";

const ToTheRoad = () => {
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();

  return (
    <Box bgcolor={theme.palette.background.default} paddingY={12}>
      <Container>
        <Grid
          container
          spacing={!breakpoint ? 3 : 16}
          alignItems="center"
          flexDirection={!breakpoint ? "column-reverse" : "row"}
        >
          <Grid item md={6} sm={12}>
            <Box component="section">
              <Image
                src={ToTheRoadImage}
                alt="To the road"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            </Box>
          </Grid>
          <Grid item md={6} sm={12}>
            <Box>
              <Typography
                component="h2"
                variant="desktopH3"
                mb={3}
                color={theme.palette.text.secondary}
              >
                {t("home:to-the-road.line1")}
              </Typography>
              <Typography
                component="h2"
                variant="desktopH3"
                mb={3}
                color={theme.palette.text.secondary}
              >
                {t("home:to-the-road.line2")}
              </Typography>
              <Typography component="h2" variant="desktopH3">
                {t("home:to-the-road.line3")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ToTheRoad;
