import { Box, Container, Typography, Button } from "@mui/material";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import DonguLogo from "public/images/dongu-white-logo.png";
import { FC } from "react";

const Blog: FC = () => {
  const { t } = useTranslation();

  const handleNavigateToBlogButtonClick = () => null;

  return (
    <Container
      maxWidth="lg"
      sx={{
        paddingY: 7,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor="GrayText"
        paddingY={6}
        paddingX={3}
      >
        <Image
          src={DonguLogo}
          alt="Dongu Blog Logo"
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />

        <Typography
          variant="body1"
          marginBottom={2}
          textAlign="center"
          color="white"
        >
          {t("home:blog.desc")}
        </Typography>

        <Button variant="contained" onClick={handleNavigateToBlogButtonClick}>
          {t("home:blog.button")}
        </Button>
      </Box>
    </Container>
  );
};

export default Blog;
