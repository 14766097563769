import { Box, Container, Typography, Button } from "@mui/material";
import { Theme, useTheme, SxProps } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "next-i18next";

import HeroDesktopImage from "public/images/home-hero-desktop.jpg";
import HeroMobileImage from "public/images/home-hero-mobile.jpg";

const Hero = () => {
  const theme: Theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up("md"));

  const { t } = useTranslation();

  const wrapperStyle: SxProps = () => ({
    width: "100%",
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(270.13deg, rgba(0, 0, 0, 0.4) 0.11%, rgba(0, 0, 0, 0) 100.8%), url(${
      breakpoint ? HeroDesktopImage.src : HeroMobileImage.src
    })`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: theme.palette.primary.contrastText,
  });

  const containerStyle: SxProps = () => ({
    width: "100%",
    height: "60vh",
    display: "flex",
    alignItems: "center",
    mt: {
      xs: 8,
      md: 10,
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      height: "75vh",
    },
  });

  return (
    <Box sx={wrapperStyle}>
      <Container sx={containerStyle} maxWidth="lg">
        <Box display="flex" flexDirection="column" gap={breakpoint ? 4 : 6}>
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="desktopH1" component="h1">
              {t("home:hero.title")}
            </Typography>
            <Typography variant="body1" component="h2">
              {t("home:hero.desc")}
            </Typography>
          </Box>

          <Box
            display="flex"
            gap={3}
            flexDirection={breakpoint ? "row" : "column"}
          >
            <Button
              href="/custom-packet"
              variant="outlined"
              style={{ background: theme.palette.primary.contrastText }}
            >
              {t("home:hero.actions.custom-packet")}
            </Button>

            <Button
              href="/packets"
              variant="text"
              style={{ color: theme.palette.primary.contrastText }}
            >
              {t("home:hero.actions.view-products")}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
