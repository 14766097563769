import { SVGProps, memo } from "react";

const SvgBeijeDot13 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="103"
    height="104"
    viewBox="0 0 103 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M103 52.3373C103 61.7061 97.9036 69.3899 93.3348 76.9368C88.6794 84.6277 82.9395 90.5327 74.966 94.9759C67.296 99.2462 59.3297 103.941 49.8958 103.941C40.462 103.941 30.7824 102.638 23.257 98.2956C15.5003 93.8165 8.71949 86.3272 4.22306 78.6003C-0.143254 71.1038 0.803758 61.6053 0.803758 52.3373C0.803758 43.0693 -1.85653 32.5339 2.50255 24.9438C6.98453 17.1449 14.6256 9.16593 22.3534 4.59315C29.9004 0.12118 40.556 1.01413 49.8958 1.01413C59.2357 1.01413 66.6382 6.06219 74.3298 10.4549C82.2745 14.9917 90.7035 18.6068 95.3445 26.3769C99.906 34.0246 103 42.9757 103 52.3373Z"
      fill={props.fill || "white"}
    />
  </svg>
);

const Memo = memo(SvgBeijeDot13);
export default Memo;
