import { SVGProps, memo } from "react";

const SvgBeijeDot16 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="50"
    height="54"
    viewBox="0 0 50 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.95919 37.0588C-1.69027 30.9992 0.155844 23.6349 1.68287 17.2311C3.01389 11.635 7.27134 7.2002 12.878 3.85019C18.4847 0.500176 21.9718 0.53841 28.3534 1.52286C35.6558 2.65069 39.0244 7.91226 43.6738 13.9719C48.3233 20.0316 51.1039 21.8571 49.5814 28.2608C48.2504 33.8569 48.182 39.2045 42.5753 42.5545C36.9686 45.9046 32.424 54.8507 26.0424 53.8662C18.74 52.7384 7.60864 43.1232 2.95919 37.0636V37.0588Z"
      fill={props.fill || "#FFC906"}
    />
  </svg>
);

const Memo = memo(SvgBeijeDot16);
export default Memo;
