import { Container, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Image from "next/image";
import { useTranslation } from "next-i18next";

import Feature1Image from "public/images/feature/feature-1.png";
import Feature2Image from "public/images/feature/feature-2.png";
import Feature3Image from "public/images/feature/feature-3.png";
import Feature4Image from "public/images/feature/feature-4.png";

const Features = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up("md"));

  const features = [
    {
      id: 1,
      title: t("home:feature.organic-products"),
      image: Feature1Image,
    },
    {
      id: 2,
      title: t("home:feature.vegan"),
      image: Feature2Image,
    },
    {
      id: 3,
      title: t("home:feature.private-subscription"),
      image: Feature3Image,
    },
    {
      id: 4,
      title: t("home:feature.sustainable-change"),
      image: Feature4Image,
    },
  ];

  return (
    <Box sx={{ backgroundColor: theme.palette.common.purple.main, py: 5 }}>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          gap={6}
          sx={{
            overflowX: !breakpoint ? "scroll" : undefined,
          }}
        >
          {features?.map((item) => (
            <Box
              key={item.id}
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              flexGrow={1}
              flexBasis={0}
            >
              <Typography
                component="h3"
                minHeight={64}
                variant="desktopSubtitle2"
                textTransform={"uppercase"}
                color={theme.palette.primary.contrastText}
                width={!breakpoint ? "45vw" : "100%"}
              >
                {item.title}
              </Typography>
              <Image
                alt={item.title}
                src={item.image}
                width={120}
                height={120}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Features;
