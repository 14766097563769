import { BeijeDot12, BeijeDot13, BeijeDot14 } from "@beije/react-icons";
import {
  useMediaQuery,
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Fade,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Image, { StaticImageData } from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import Why1Image from "public/images/home-why1.png";
import Why2Image from "public/images/home-why2.png";
import Why3Image from "public/images/home-why3.png";
import { useState } from "react";

const Why = () => {
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();
  const router = useRouter();

  const [selectedReason, setSelectedReason] = useState<number>(1);
  const [selectedImage, setSelectedImage] = useState(Why1Image);
  const [imageTransition, setimageTransition] = useState(true);

  const reasons = [
    {
      id: 1,
      title: t("home:why.reasons.quality"),
      description: t("home:why.reasons.quality-description"),
      image: Why1Image,
    },
    {
      id: 2,
      title: t("home:why.reasons.easy"),
      description: t("home:why.reasons.easy-description"),
      image: Why2Image,
    },
    {
      id: 3,
      title: t("home:why.reasons.reasonable"),
      description: t("home:why.reasons.reasonable-description"),
      image: Why3Image,
    },
  ];

  const handleClickOnReason = async (id: number, image: StaticImageData) => {
    setSelectedReason(0);
    setSelectedImage(image);
    setSelectedReason(id);
  };

  return (
    <Box
      bgcolor={theme.palette.common.beije.secondary.lime.main}
      paddingY={17}
      position="relative"
    >
      <Container>
        <Grid
          container
          alignItems="center"
          flexDirection={!breakpoint ? "column-reverse" : "row"}
          spacing={!breakpoint ? 3 : 0}
        >
          <Grid item md={6} sm={12}>
            <Box textAlign={!breakpoint ? "center" : "left"}>
              <Typography component="h2" variant="desktopH1" mb={6}>
                {t("home:why.title")}
              </Typography>

              {reasons.map((item) => (
                <Box key={item.id} mb={5}>
                  <Typography
                    component="p"
                    variant="h6"
                    mb={selectedReason === item.id ? 3 : undefined}
                    color={
                      selectedReason !== item.id
                        ? theme.palette.text.disabled
                        : undefined
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClickOnReason(item.id, item.image)}
                  >
                    {item.id}. {item.title}
                  </Typography>
                  <Typography
                    component="p"
                    variant="desktopBody1"
                    width={!breakpoint ? "100%" : "80%"}
                    maxHeight={
                      !breakpoint || selectedReason === item.id ? 500 : 0
                    }
                    overflow="hidden"
                    style={{ transition: "max-height 200ms" }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              ))}

              <Link href="/know-us/why-beije">
                <Button variant="contained" size="large">
                  {t("home:why.button")}
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item md={6} sm={12}>
            <Box component="section" position="relative">
              {/* TODO: Fix Fade */}
              <Fade
                appear={true}
                in={selectedReason - 1 < 0 ? false : true}
                timeout={10}
              >
                <div>
                  <Image
                    src={selectedImage}
                    alt="Why beije"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                </div>
              </Fade>
              <BeijeDot12
                style={{ position: "absolute", top: "8%", left: "-6%" }}
                display={!breakpoint ? "none" : undefined}
              />
              <BeijeDot13
                style={{ position: "absolute", bottom: "6%", right: "-10%" }}
                display={!breakpoint ? "none" : undefined}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <BeijeDot14
        style={{ position: "absolute", top: "12%", right: "0" }}
        display={!breakpoint ? "none" : undefined}
      />
    </Box>
  );
};

export default Why;
