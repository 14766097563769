import { SVGProps, memo } from "react";

const SvgBeijeDot17 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="152"
    height="153"
    viewBox="0 0 152 153"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M152 76.7878C152 90.6136 144.479 101.953 137.737 113.09C130.867 124.44 122.396 133.154 110.629 139.711C99.3106 146.013 87.5544 152.941 73.6327 152.941C59.7109 152.941 45.4264 151.018 34.321 144.61C22.8742 138 12.8676 126.948 6.23208 115.545C-0.211403 104.482 1.18613 90.4648 1.18613 76.7878C1.18613 63.1108 -2.73973 47.5634 3.69309 36.3625C10.3073 24.8534 21.5834 13.0786 32.9875 6.33038C44.1249 -0.269024 59.8496 1.04873 73.6327 1.04873C87.4158 1.04873 98.3398 8.49829 109.691 14.9808C121.415 21.6758 133.854 27.0107 140.703 38.4773C147.434 49.7632 152 62.9726 152 76.7878Z"
      fill={props.fill || "white"}
    />
  </svg>
);

const Memo = memo(SvgBeijeDot17);
export default Memo;
