import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Link from "next/link";
import { useTranslation } from "next-i18next";

import { Packet } from "src/components/Packets";
import { useGetPacketsQuery } from "src/store/apis";

const PopularPackets = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const breakpoint = useMediaQuery(theme.breakpoints.up("md"));

  const { data: packets } = useGetPacketsQuery({
    limit: 4,
    sort: "-usageCount",
  });

  return (
    <Box py={6}>
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center" gap={6}>
          <Typography component="h3" variant="desktopH1" textAlign="center">
            {t("home:popular-product.title")}
          </Typography>

          <Grid container spacing={3} width="100%">
            {packets?.data
              ?.slice(0, !breakpoint ? 1 : 4)
              ?.map((popularProduct) => (
                <Grid item key={popularProduct._id} md={3} sm={12} width="100%">
                  <Packet item={popularProduct} purchaseType="subscription" />
                </Grid>
              ))}
          </Grid>

          <Link href="/packets">
            <Button variant="contained" size="large" fullWidth={!breakpoint}>
              {t("home:popular-product.button")}
            </Button>
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default PopularPackets;
