import { BeijeDot15, BeijeDot16, BeijeDot17 } from "@beije/react-icons";
import {
  useMediaQuery,
  Container,
  Box,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";

import DonationCultureImage from "public/images/donation-culture.webp";

const DonationCulture = () => {
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();

  return (
    <Box bgcolor={theme.palette.common.orange.dark} paddingY={12}>
      <Container>
        <Grid container spacing={!breakpoint ? 3 : 16} alignItems="center">
          <Grid item md={6} sm={12} textAlign={!breakpoint ? "center" : "left"}>
            <Box color={theme.palette.primary.contrastText}>
              <Typography component="h4" variant="desktopH1" mb={4}>
                {t("home:donation-culture.title")}
              </Typography>
              <Typography component="p" variant="desktopCaption" mb={2}>
                {t("home:donation-culture.line1")}
              </Typography>
              <Typography component="p" variant="desktopCaption" mb={2}>
                {t("home:donation-culture.line2")}
              </Typography>
              <Typography component="p" variant="desktopCaption" mb={4}>
                {t("home:donation-culture.line3")}
              </Typography>
              <Link href="/donation-culture">
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: theme.palette.background.white,
                    borderColor: theme.palette.background.white,
                    "&:hover": {
                      backgroundColor: theme.palette.background.white,
                    },
                  }}
                >
                  {t("home:donation-culture.button")}
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item md={6} sm={12}>
            <Box component="section" position="relative">
              <Image
                src={DonationCultureImage}
                alt="Donation Culture"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <BeijeDot15
                style={{ position: "absolute", top: "8%", left: "-8%" }}
                display={!breakpoint ? "none" : undefined}
              />
              <BeijeDot16
                style={{ position: "absolute", top: "22%", right: "-6%" }}
                display={!breakpoint ? "none" : undefined}
              />
              <BeijeDot17
                style={{ position: "absolute", bottom: "-14%", right: "12%" }}
                display={!breakpoint ? "none" : undefined}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DonationCulture;
