import { SVGProps, memo } from "react";

const SvgBeijeDot15 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="102"
    height="103"
    viewBox="0 0 102 103"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M102 50.846C102 59.959 93.9993 66.5943 89.5529 73.9435C85.0222 81.4257 81.2572 88.7679 73.5023 93.0795C66.0425 97.23 57.8732 102.941 48.6995 102.941C39.5257 102.941 30.4573 99.1898 23.1309 94.9623C15.5868 90.6087 8.16212 84.1904 3.79299 76.6732C-0.4497 69.38 0.379179 59.861 0.379179 50.839C0.379179 41.817 -1.41907 31.6961 2.8166 24.3119C7.17168 16.7247 18.2982 15.3878 25.8142 10.9363C33.1546 6.5898 39.617 0.941406 48.6995 0.941406C57.7819 0.941406 64.7149 6.28882 72.1958 10.5584C79.9226 14.9679 87.4175 18.6005 91.9271 26.1597C96.3665 33.5929 102 41.726 102 50.839V50.846Z"
      fill={props.fill || "#93261B"}
    />
  </svg>
);

const Memo = memo(SvgBeijeDot15);
export default Memo;
